<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('settings-change-document-template',167)">
        <ValidationObserver ref="form" >

            <app-row-left-label :label="trans('settings-version',167)">
                <app-input v-model="cArgs.version" validatorName="Versie" validatorRules="max:30" mask="lettersE2-30" />
            </app-row-left-label>

            <app-row-left-label :label="trans('date-start',3)" >
                <app-date v-model="cArgs.date_from" validatorName="Datum vanaf" validatorRules="required|date-check"/>
            </app-row-left-label>

            <app-row-left-label :label="trans('date-end',3)" >
                <app-date v-model="cArgs.date_to" validatorName="Datum naar" :validatorRules="'required|date-check|date-more-than:'+cArgs.date_from"/>
            </app-row-left-label>

            <app-row-left-label :label="trans('status',3)">
                <app-select v-model="cArgs.status" reduceValue :options="statuses" :clearable="false" />
            </app-row-left-label>


         </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="edit" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>

import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import { datecheck } from "@validations";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args", "filters", "return"],

    created(){
        
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data() {
        return {
            loading: false,
            file_data: {
                    file: null,
                    file_name: "",
                    size: null,
                    status: 0
                },
            parameters:{
                search:"",
                sort_by: "id",
                sort_desc: false,
                args:[],
            },
            document_type: null,
            statuses: [
                {name: this.trans('active',3), value: 1},
                {name: this.trans('blocked',3), value: 0},
                {name: this.trans('settings-in-realisation',167), value: -1}
            ]
        };
    },


    methods: {
        edit() {
            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                }else{
            this.loading = true;
                    if(this.args.file_data == undefined){
                        this.cArgs.file_data = {file_name:null};
                    }
                    axios
                        .post("settings/documents/change", {
                            id_document_data_version: this.args.id_document_data_version,
                            id_document_const_type: JSON.stringify(this.document_type),
                            date_from: this.args.date_from,
                            date_to: this.args.date_to,
                            version: this.args.version,
                            status: this.args.status,
                            file_name: this.args.file_data.file_name,
                            filters: JSON.stringify(this.filters),
                        })
                        .then((res) => {
                            this.cReturn = res.data.items;
                            this.loading = false;
                            this.$emit('update:result', 1);
                            this.computedShow = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        });
                    }
            })
                
        },

        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style>

.calendarCustomBtn .btn {
    width: 32px !important;
    height: 32px !important;
    font-size: 14px !important;
    line-height: 1 !important;
    margin: 3px auto !important;
    padding: 9px 0 !important;
}
</style>
