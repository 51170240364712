 <template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('settings-add-document-template',167)">

        <ValidationObserver ref="form">
            <app-row-left-label :label="trans('settings-document-template-type',207)">
                <app-select v-model="document_type" type="getDocumentTemplatesTypes" validatorName="Type documentsjabloon" validatorRules="required"/>
            </app-row-left-label>

            <app-row-left-label :label="trans('date-start',3)">
                <app-date v-model="date_from" validatorName="Datum vanaf" validatorRules="required|date-check" />
            </app-row-left-label>

            <app-row-left-label :label="trans('date-end',3)" >
                <app-date v-model="date_to" validatorName="Datum naar" :validatorRules="'required|date-check|date-more-than:'+date_from"/>
            </app-row-left-label>

            <app-row-left-label :label="trans('settings-version',167)">
                <app-input v-model="document_version" validatorName="Versie" validatorRules="max:30" mask="lettersE2-30" />
            </app-row-left-label>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="addDocumentTemplate" :loading="loading">
                {{trans('add',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import axios from "@axios";
import { datecheck } from "@validations";

export default {
    components: { 
      ValidationObserver
      
    },

    props:{
        args: { type: Object },
        show: { type: Boolean },
        result: { type: Number },
        return: { type: Object },
        filters: { type: Object }
    },

    computed:{
        computedShow:{
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
        cFilters: {
            get() { return this.filters },
            set(value) { this.$emit('update:filters', value) }
        }
    },

    data(){
        return{
            loading: false,
            date_from: null,
            date_to: null,
            document_type: null,
            document_version: null,
        }
    },

    methods:
    {
        addDocumentTemplate(){
            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                }else{
                    this.loading = true;

                    axios
                        .post("settings/documents/add", {
                            filters: JSON.stringify(this.filters),
                            document_type: this.document_type.value,
                            date_from: this.date_from,
                            date_to: this.date_to,
                            document_version: this.document_version
                        }).
                        then((res) => {
                            this.cReturn = res.data.items;
                            this.cFilters.list_type = 3;
                            this.loading = false;
                            this.$emit('update:result', 1);
                            this.computedShow = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                }
            });

        },

        closeModal(){
            this.computedShow = false;
        }
    }

}
</script>

<style>

</style>