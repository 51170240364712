<template>
    <div style="min-height:200px">
        <b-card>
            <app-view-table :title="trans('settings-document-templates',167)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items" :fields="fields" :filterOptions="status_options" :isSearch="true" :isFilterOptions="true">
                
                <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="openDocument(cell.data.item)">{{trans('show-document',3)}}</b-dropdown-item>
                    <b-dropdown-item @click="changeVersion(cell.data.item)">{{trans('change',175)}}</b-dropdown-item>
                    <b-dropdown-item v-if="cell.data.item.status == -1" @click="deleteVersion(cell.data.item)">{{trans('decline',175)}}</b-dropdown-item>
                </template>
                
                <template v-slot:buttons>
                    <app-button type="primary" @click="addDocumentTemplate">Documentsjabloon toevoegen</app-button>
                </template>
            </app-view-table>

        <settings-documents-templates-edit v-if="mModalChange.show" :show.sync="mModalChange.show" :filters="filters" :result.sync="mModalChange.result" :args="mModalChange.args" :return.sync="items" />
        <settings-documents-templates-add v-if="mDocumentTemplateAdd.show" :show.sync="mDocumentTemplateAdd.show" :filters="filters" :args="mDocumentTemplateAdd.args" :result.sync="mDocumentTemplateAdd.result" :return.sync="items" />
        
        </b-card>  
    </div>
</template>

<script>

// #ID _99-06.00 - KNI 2022-01-13

import axios from "@axios";
import SettingsDocumentsTemplatesEdit from "./SettingsDocumentsTemplatesChange.vue";
import SettingsDocumentsTemplatesAdd from "./SettingsDocumentsTemplatesAdd.vue";
import AppAlerts from '@core/scripts/AppAlerts';


export default {
    components: { 
        SettingsDocumentsTemplatesEdit,
        SettingsDocumentsTemplatesAdd
    },

    mounted(){
        this.onCreateThis();
    },

    data() {
        return {
            mModalChange: {show: false, 
                            args: { id_document_data_version: null, 
                                    version: null, 
                                    date_from: null,
                                    date_to: null,
                                    status: null,
                                },
                            result: 0,
                            return: null },
            mDocumentTemplateAdd: { args: null, show: false, result: 0, return: null},
            loading_list: false,
            status_options: [
                { value: 1, name: this.trans('active',3) },
                { value: 2, name: this.trans('blocked',3) },
                { value: -2, name: "" },
                { value: 3, name: this.trans('settings-in-realisation',167)} 
            ],
            filters: {
                page: 1,
                per_page: 10,
                list_type: 1,
                filtering:{},
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                sortDesc: true,
                search: "",
                status: 1,
                changed: 0,
            },
            alert_class: new AppAlerts(),
            fields: [
                { key: "id_document_data_version", label: this.trans('settings-id',207), thStyle: { "min-width": "80px", "max-width": "80px"}, visible: true, filtering: false },
                { key: "type_name", label: this.trans('type',182), thStyle: { "min-width": "80px", "width": "100%" }, visible: true, filtering: false },
                { key: "date_from", label: this.trans('date-start',3), thStyle: { "min-width": "180px", "max-width": "180px" }, visible: true, filtering: false },
                { key: "date_to",  label: this.trans('date-end',3), thStyle: { "min-width": "180px", "max-width": "180px" }, visible: true, filtering: false },
                { key: "version", label: this.trans('settings-version',167), thStyle: { "min-width": "180px", "max-width": "180px" }, visible: true, filtering: false },
            ],
            items: [],
        };
    },

    methods: {
        getItems() {
            this.loading_list = true;
            axios
                .post("settings/documents/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.items = res.data.items;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        changeVersion: function(data) {
            this.mModalChange.args.id_document_data_version = data.id_document_data_version;
            this.mModalChange.args.version = data.version;
            this.mModalChange.args.date_from = data.date_from;
            this.mModalChange.args.date_to = data.date_to;
            this.mModalChange.args.id_document_const_type = data.id_document_const_type;
            this.mModalChange.args.type_name = data.type_name;
            this.mModalChange.args.status = data.status;
            this.mModalChange.show = true;
        },

        addDocumentTemplate(){
            this.mDocumentTemplateAdd.show = true;
        },
        deleteVersion(data){
             this.alert_class.openAlertConfirmation(this.trans('settings-reject-document-confirmation',208)).then(res => {
                if(res == true){
                    this.loading_list = true;
                    axios
                        .post("settings/documents/delete", {
                            filters: JSON.stringify(this.filters),
                            id_document_data_version: data.id_document_data_version
                        })
                        .then((res) => {
                            this.items = res.data.items;
                            this.filters.page = res.data.page;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        });
                }
             });
        },

        openDocument(data){
            axios
                .post("settings/documents/openDocument", {
                    id_document_data_version: data.id_document_data_version,
                    id_document_const_type: data.id_document_const_type
                },
                {
                    responseType: 'blob', 
                    crossDomain: true,
                })
                .then((res) => {
                    if(res.data.size > 2){
                        var mine_type = "application/pdf";
                        const url = window.URL.createObjectURL(new Blob([res.data], {type : mine_type}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.target= "_blank";
                        mine_type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                        mine_type == 'application/msword' ||
                        mine_type == 'text/plain'
                                        ? link.setAttribute('download', data.type_name):link.setAttribute('open', data.type_name); 
                        link.setAttribute('open', data.type_name); 
                        document.body.appendChild(link);
                        link.click();
                    }
                    else{
                        this.alert_class.openAlertWarning(this.trans('file-not-found',180)).then(res => {});
                    }

                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                    this.alert_class.openAlertWarning(this.trans('file-not-found',180)).then(res => {})
                });
        },
        onCreateThis(){

            this.getItems();

            this.$watch('filters.list_type', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getItems();
                } 
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getItems();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getItems();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getItems();
                } 
            });

           
            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getItems();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getItems();
                }   
            });
        }
    },
};
</script>

<style scoped></style>
